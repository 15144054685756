import React from "react";
import "./OurTeam.css";
import { FaFacebook,FaInstagram , FaGithub, FaLinkedin } from "react-icons/fa";
import { CgWebsite } from "react-icons/cg";

const teamMembers = [
  {
    name: "MR. ABHYASI",
    role: "CEO",
    photo: "../../assets/ABHYASI.JPG",
    social: {
      github: "https://github.com/mrabhyasi",
      linkedin: "https://www.linkedin.com/in/mrabhyasi/",
      instagram: "https://www.instagram.com/mrabhyasi/",
      website: "https://www.abhishekabhyasi.com",
    },
  },
  {
    name: "MR. KOBIN",
    role: "CTO",
    photo: "../../assets/KOBIN.jpg",
    social: {
        github: "https://github.com/kobinneupane",
        linkedin: "https://www.linkedin.com/in/kobinneupane/",
        instagram: "https://www.instagram.com/kobin_neupane/",
        website: "",
    },
  },

  {
    name: "MR. BISHAL",
    role: "CFO",
    photo: "../../assets/BISHAL.JPG",
    social: {
        github: "",
        linkedin: "",
        instagram: "https://www.facebook.com/johndoe",
        website: "",
    },
  },
  {
    name: "MR. RANJAN",
    role: "CMO",
    photo: "../../assets/RANJAN.JPG",
    social: {
        github: "",
        linkedin: "",
        instagram: "https://www.instagram.com/ranjan_khatiwada11/",
        website: "",
    },
  },
];

const OurTeam = () => {
  return (
    <div className="team-container">
      <h2 className="team-heading">OUR TEAM</h2>
      <div className="team-grid">
        {teamMembers.map((member, index) => (
          <div key={index} className="team-card">
            <img src={member.photo} alt={member.photo} className="team-photo" />
            <h2 className="team-name">{member.name}</h2>
            <p className="team-role">{member.role}</p>
            <div className="team-social-links">
              
              <a
                href={member.social.github}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaGithub size={24} />
              </a>
              <a
                href={member.social.linkedin}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedin size={24} />
              </a>
              <a
                href={member.social.website}
                target="_blank"
                rel="noopener noreferrer"
              >
                <CgWebsite  size={24} />
              </a>
              <a
                href={member.social.instagram}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagram size={24} />
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OurTeam;
