import React from "react";
import "./Services.css";
import {
  FaCode,
  FaMobileAlt,
  FaBullhorn,
  FaPaintBrush,
  FaCalendarAlt,
  FaSupport,
} from "react-icons/fa";
import { BiSupport } from "react-icons/bi";

const services = [
  {
    icon: <FaCode />,
    title: "WEB DEVELOPMENT",
    description:
      "Binary SoftTech provides top-notch web development services, playing a crucial role in shaping the digital landscape for businesses. Our web development solutions encompass everything from designing user interfaces and crafting responsive layouts to coding complex functionalities and ensuring seamless user experiences. We leverage technologies like HTML, CSS, JavaScript, and various backend languages to build interactive and visually appealing websites that attract and engage users. Our team stays ahead of industry trends, incorporating modern frameworks and libraries such as React to deliver dynamic and high-performing web applications.",
  },
  {
    icon: <FaMobileAlt />,
    title: "APP DEVELOPMENT",
    description: "At Binary SoftTech, we specialize in creating innovative mobile applications that cater to the needs of modern users. Our app development process combines creativity with technical expertise, covering everything from concept development and design to coding, testing, and deployment. We focus on user experience, ensuring that our apps are intuitive, efficient, and tailored to meet user needs. Whether it's developing native iOS and Android apps or using cross-platform frameworks like Flutter and React Native, we deliver high-quality applications that enhance connectivity and productivity.",
  },
  {
    icon: <FaBullhorn />,
    title: "DIGITAL MARKETING",
    description:
      "Binary SoftTech offers comprehensive digital marketing services to help businesses connect with their audiences in the digital age. Our strategies include Search Engine Optimization (SEO), social media marketing, email marketing, content marketing, and pay-per-click (PPC) advertising. We engage with audiences in a personalized and interactive manner, using data-driven insights to refine our strategies and maximize ROI. By leveraging digital channels such as search engines, social media, and email, we help businesses increase their visibility, attract more traffic, and achieve their marketing goals.",
  },
  {
    icon: <FaPaintBrush />,
    title: "GRAPHIC DESIGNING",
    description: "Our graphic designing services at Binary SoftTech blend art and technology to communicate ideas visually. We create compelling designs that capture attention and convey messages effectively, whether for branding, advertising, web design, or product packaging. Our designers excel in typography, color theory, imagery, and layout, crafting visually harmonious designs that resonate with target audiences. We ensure consistency in design across different media, helping businesses build strong brand identities and stand out in a competitive market.",
  },
  {
    icon: <FaCalendarAlt />,
    title: "EVENT PLANNING",
    description: "Binary SoftTech provides meticulous event planning services to organize and coordinate all elements of an event, ensuring its success. From corporate conferences and weddings to music festivals and community fundraisers, our event planning services cover concept development, budgeting, venue selection, logistics, vendor coordination, marketing, and post-event evaluation. We focus on creating memorable experiences by managing timelines, resources, and people effectively. Our attention to detail, problem-solving skills, and ability to stay calm under pressure ensure that every event we plan is a resounding success.",
  },
  {
    icon: < BiSupport />,
    title: "IT SUPPORT",
    description: "Binary SoftTech offers reliable IT support services to ensure the smooth operation of your technology infrastructure. Our IT support team provides comprehensive solutions, including network setup and management, hardware and software installation, troubleshooting, and ongoing maintenance. We understand the critical role technology plays in your business operations, and we are dedicated to providing timely and effective support to minimize downtime and keep your systems running efficiently. Whether it's addressing technical issues, enhancing cybersecurity, or implementing new technologies, our IT support services are designed to meet your unique needs and help your business thrive in a digital world.",
  },
];

const Services = () => {
  return (
    <div className="services-container">
      <h2 className="services-heading">SERVICES</h2>
      <div className="services-grid">
        {services.map((service, index) => (
          <div key={index} className="service-card">
            <div className="service-icon">{service.icon}</div>
            <h3 className="service-title">{service.title}</h3>
            <p className="service-description">{service.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
