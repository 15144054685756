import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import About from './components/About/About';
import Services from './components/services/Services';
import OurTeam from './components/Ourteam/OurTeam';
import Pricing from './components/Pricing/Pricing';
import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer';

import './App.css';
import LandingPage from './components/Home/LandingPage';

const App = () => {
    return (
      <div>
      <Navbar />
      <section id="home">
          <LandingPage />
      </section>
      <section id="about">
          <About />
      </section>
      <section id="services">
          <Services />
      </section>
      <section id="team">
          <OurTeam />
      </section>
      <section id="pricing">
          <Pricing />
      </section>
      <section id="contact">
          <Contact />
      </section>
      <Footer />
  </div>
    );
};

export default App;
