import React, { useState } from 'react';
import { Link as ScrollLink } from 'react-scroll';
import './Navbar.css';
import { FaBars, FaTimes } from 'react-icons/fa';
import Logo from '../LOGO.png'; // Replace with the actual path to your logo image

const Navbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleMenuToggle = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <nav className="navbar">
            <div className="navbar-content">
                <ScrollLink to="home" smooth={true} duration={500} className="navbar-logo" onClick={handleMenuToggle}>
                    <img src={Logo} alt="" className="navbar-logo-img" />
                   
                </ScrollLink>
                <div className="navbar-icons">
                    <div className="menu-toggle" onClick={handleMenuToggle}>
                        {isMenuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
                    </div>
                </div>
                <ul className={`navbar-links ${isMenuOpen ? 'active' : ''}`}>
                    <li><ScrollLink to="LandingPage" smooth={true} duration={500} onClick={handleMenuToggle}>Home</ScrollLink></li>
                    <li><ScrollLink to="about" smooth={true} duration={500} onClick={handleMenuToggle}>About</ScrollLink></li>
                    <li><ScrollLink to="services" smooth={true} duration={500} onClick={handleMenuToggle}>Services</ScrollLink></li>
                    <li><ScrollLink to="team" smooth={true} duration={500} onClick={handleMenuToggle}>Our Team</ScrollLink></li>
                    <li><ScrollLink to="pricing" smooth={true} duration={500} onClick={handleMenuToggle}>Pricing</ScrollLink></li>
                    <li><ScrollLink to="contact" smooth={true} duration={500} onClick={handleMenuToggle}>Contact</ScrollLink></li>
                </ul>
            </div>
        </nav>
    );
};

export default Navbar;
