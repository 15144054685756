import React from "react";
import "./Pricing.css";

const pricingPlans = [
  {
    name: "Basic",
    price: "Rs. 25k",
    features: [
      "Responsive Web Design",
      "5 GB Hosting Space",
      "Monthly Website Backups",
      "24/7 Customer Support",
    ],
  },
  {
    name: "Standard",
    price: "Rs. 75k",
    features: [
      "All Basic Plan Features",
      "10 GB Hosting Space",
      "Custom Graphics and Design",
      "Email Marketing Integration",
    ],
  },
  {
    name: "Premium",
    price: "200k",
    features: [
      "All Standard Plan Features",
      "20 GB Hosting Space",
      "Priority Customer Support",
      "Mobile App Development",
      "Social Media Management",
      "Comprehensive Analytics and Reporting",
    ],
  },
];

const Pricing = () => {
  return (
    <div id="pricing" className="pricing-container">
      <h2 className="pricing-heading">PRICING</h2>
      <div className="pricing-grid">
        {pricingPlans.map((plan, index) => (
          <div key={index} className="pricing-card">
            <h3 className="pricing-plan-name">{plan.name}</h3>
            <p className="pricing-plan-price">{plan.price}</p>
            <ul className="pricing-plan-features">
              {plan.features.map((feature, featureIndex) => (
                <li key={featureIndex}>{feature}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Pricing;
