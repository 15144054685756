import React, { useEffect, useState } from 'react';
import './Footer.css';
import { FaFacebook,  } from 'react-icons/fa';

const Footer = () => {
    const [timeSpent, setTimeSpent] = useState({
        hours: 0,
        minutes: 0,
        seconds: 0,
    });

    useEffect(() => {
        const startTime = Date.now();

        const updateTimeSpent = () => {
            const now = Date.now();
            const diff = now - startTime;
            const diffInSeconds = Math.floor(diff / 1000);
            const hours = Math.floor(diffInSeconds / 3600);
            const minutes = Math.floor((diffInSeconds % 3600) / 60);
            const seconds = diffInSeconds % 60;

            setTimeSpent({ hours, minutes, seconds });
        };

        const intervalId = setInterval(updateTimeSpent, 1000);
        return () => clearInterval(intervalId);
    }, []);

    return (
        <footer id='Footer' className="footer">
            <div className="footer-content">
                <p>&copy; BinarySoftTech 2024</p>
                <div className="social-links">
                    <a href="https://www.facebook.com/your.binarysofttech" target="_blank" rel="noopener noreferrer">
                        <FaFacebook size={24} />
                    </a>
                    
                </div>
                <p>You have spent {timeSpent.hours}h {timeSpent.minutes}m {timeSpent.seconds}s on this website. Thank you!</p>
            </div>
        </footer>
    );
};

export default Footer;
