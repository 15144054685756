import React from 'react';
import './LandingPage.css';

const LandingPage = () => {
    return (
        <div id='LandingPage' className="landing-page">
            <div className="content">
                <h1 className="main-text">
                    {Array.from("Binary  SoftTech").map((char, index) => (
                        <span key={index} className="hover-char">
                            {char}
                        </span>
                    ))}
                </h1>
                <p className="sub-text">
                    "GROW WITH US,CONNECT WITH TECH"
                </p>
                <div className="scroll-indicator">
                    <div className="mouse"></div>
                </div>
            </div>
        </div>
    );
};

export default LandingPage;
