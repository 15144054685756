import React from "react";
import "./About.css";
import companyLogo from '../ABOUT.png'; 

const About = () => {
  return (
    <div id="about" className="about-container">
      <h2 className="about-heading">ABOUT US</h2>
      <div className="about-content">
        <img src={companyLogo} alt="Company Logo" className="about-logo" />
        <div className="about-text">
          <p>
            Welcome to Binary SoftTech! We are a passionate team of four
            co-founders who are currently pursuing our undergraduate degrees in
            Computer Science at Tribhuvan University, Institute of Science and
            Technology (TU/IOST). Our mission is to provide exceptional digital
            solutions specialized to meet the unique needs of each client.
          </p>

          <p>
            At Binary SoftTech, we offer a range of services including website
            development, app development, digital marketing, graphic designing,
            event planning, and IT support. We are dedicated to delivering high-quality,
            innovative solutions that help businesses grow and succeed in the
            digital age.
          </p>

          <h3>Our Services:</h3>
          <ul>
            <li>
              <strong>Website Development:</strong> We build responsive,
              user-friendly websites that look great on all devices. Using the
              latest technologies, we ensure your website is fast, secure, and
              easy to navigate.
            </li>
            <li>
              <strong>App Development:</strong> From concept to deployment, we
              develop high-performance mobile applications designed to engage
              users and drive business success.
            </li>
            <li>
              <strong>Digital Marketing:</strong> Our digital marketing
              services, including SEO, social media marketing, PPC, and content
              marketing, are designed to help you reach your target audience and
              achieve your marketing goals.
            </li>
            <li>
              <strong>Graphic Designing:</strong> Our talented designers create
              visually appealing graphics that effectively communicate your
              brand message, from logos to marketing materials.
            </li>
            <li>
              <strong>Event Planning:</strong> We offer professional event
              planning services to make your events memorable and successful,
              handling everything from corporate events to private parties.
            </li>
            <li>
              <strong>IT Support:</strong> Binary SoftTech offers reliable IT
              support services to ensure the smooth operation of your technology
              infrastructure.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default About;
