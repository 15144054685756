import React, { useRef, useState } from 'react';
import emailjs from 'emailjs-com';
import './Contact.css';

const Contact = () => {
    const form = useRef();
    const [statusMessage, setStatusMessage] = useState('');

    const sendEmail = (e) => {
        e.preventDefault();
        setStatusMessage('Sending message...');

        emailjs.sendForm('service_dt0on55', 'template_wcxbv92', form.current, 'dQZDHTequaDXsXROn')
            .then((result) => {
                console.log(result.text);
                setStatusMessage('Message sent successfully!');
            }, (error) => {
                console.log(error.text);
                setStatusMessage('Failed to send the message. Please try again later.');
            });

        e.target.reset();
    };

    return (
        <div id='Contact' className="contact-container">
            <h2 className="contact-heading">CONTACT US</h2>
            <div className="contact-content">
                <div className="contact-info">
                    <p>Address: Jhapa, Nepal</p>
                    <p>Contact CMO: +977-9814014683</p>
                    <p>Email: mail@binarysofttech.com</p>
                </div>
                <form ref={form} onSubmit={sendEmail} className="contact-form">
                    <label>Name</label>
                    <input type="text" name="user_name" required />
                    <label>Email</label>
                    <input type="email" name="user_email" required />
                    <label>Message</label>
                    <textarea name="message" required />
                    <button type="submit">Send</button>
                    <p className="status-message">{statusMessage}</p>
                </form>
            </div>
        </div>
    );
};

export default Contact;
